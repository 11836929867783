/*----------------------------------------------------- Global Search --------------------------------------------*/
.GlobalFiltersContainer {
  background-color: #f5f5f5;
  padding-top: 10px;
  padding-bottom: 6px;
}

.GlobalSearchContainer {
  position: relative;
  background-color: #f5f5f5;
  padding: 10px 50px 6px 80px;
  background-image: url(/assets/images/icons/icon_search_black.svg);
  background-repeat: no-repeat;
  background-position: left 50px center;
  background-size: auto 23px;
}

.GlobalSearchContainer .FormContainer {
  width: calc(100% - 200px);
}

.GlobalSearchContainer .FormSectionContainer.FullWidth {
  width: 100%;
}

.GlobalSearchContainer .FieldComboBox.FullWidth {
  width: 100%;
  margin-bottom: 0px;
}

.GlobalSearchContainer .Field_FullWidth input[type='text'] {
  width: 100%;
  background-color: #f5f5f5;
  border: 1px solid #f5f5f5;
  font-size: 16px;
  height: 40px;
  line-height: 40px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.GlobalSearchContainer.Active,
.GlobalSearchContainer.Active .Field_FullWidth input[type='text'] {
  background-color: #f3fbff;
}

.ClearSearchContainer {
  display: none;
  height: 55px;
  width: 60px;
  position: absolute;
  right: 200px;
  background-image: url('/assets/images/icons/icon_close_blue.svg');
  background-position: center;
  background-size: 20px;
  background-repeat: no-repeat;
  top: 0px;
}

.ClearSearchContainer:hover {
  cursor: pointer;
}

.GlobalSearchContainer.Active .ClearSearchContainer {
  display: block;
}

/*----------------------------------------------------- Advanced Search --------------------------------------------*/
.AdvancedSearchLink {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 55px;
  line-height: 55px;
  width: 200px;
  padding: 0px 70px 0px 10px;
  text-align: right;
  background-image: url(/assets/images/icons/icon_settings_blue.svg);
  background-position: right 30px center;
  background-repeat: no-repeat;
  background-size: 20px;
  color: #1f92c2;
  background-color: #f5f5f5;
}

.AdvancedSearchLink:hover {
  color: #10147e;
  cursor: pointer;
}

.AdvancedSearchFieldSet {
  position: relative;
}

.AdvancedSearchFieldSet .FieldComboBox.Half {
  width: calc(50% - 15px);
}

.RemoveSearchFilter {
  position: absolute;
  height: 30px;
  width: 30px;
  right: 0px;
  top: 5px;
  background-image: url('/assets/images/icons/icon_add_blue.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px 20px;
  transform: rotate(45deg);
}

.RemoveSearchFilter:hover {
  cursor: pointer;
}

/*----------------------------------------------------- Search Results Tabs --------------------------------------------*/
.SearchResultsMenu {
  width: 100%;
  overflow: auto;
  transition: all 0.3s;
  -webkit-overflow-scrolling: touch;
}

.SearchResultsTabsContainer {
  display: inline-flex;
  flex: wrap;
}

.SearchResultsTab {
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  background-color: #f5f5f5;
  margin-right: 10px;
  padding: 0px 20px;
  display: inline-table;
  white-space: nowrap;
}

.SearchResultsTab.Active {
  color: #ffffff;
  background-color: #0096db;
}

.SearchResultsTab:hover {
  background-color: #22252e;
  color: #ffffff;
  cursor: pointer;
}

.SearchResultsTab.HasLeftIcon {
  padding-left: 45px;
  background-position: left 17px center;
  background-size: auto 15px;
  background-repeat: no-repeat;
}

.SearchResultsTab.Leads {
  background-image: url(/assets/images/icons/icon_profile_grey.svg);
}

.SearchResultsTab.PreApps {
  background-image: url(/assets/images/icons/icon_send_grey.svg);
}

.SearchResultsTab.Application {
  background-image: url(/assets/images/icons/icon_app_grey.svg);
}

.SearchResultsTab.Closed {
  background-image: url(/assets/images/icons/icon_archive_grey.svg);
}

.SearchResultsTab.Active.Leads,
.SearchResultsTab.Leads:hover {
  background-image: url(/assets/images/icons/icon_profile_white.svg);
}

.SearchResultsTab.Active.PreApps,
.SearchResultsTab.PreApps:hover {
  background-image: url(/assets/images/icons/icon_send_white.svg);
}

.SearchResultsTab.Active.Application,
.SearchResultsTab.Application:hover {
  background-image: url(/assets/images/icons/icon_app_white.svg);
}

.SearchResultsTab.Active.Closed,
.SearchResultsTab.Closed:hover {
  background-image: url(/assets/images/icons/icon_archive_white.svg);
}

/*----------------------------------------------------- Search Results Sub Header --------------------------------------------*/
.SearchResultsSubHeader {
  display: flex;
  flex-wrap: wrap;
}

.NewItemContainer {
  padding: 30px 0px 0px 50px;
  margin-left: auto;
  display: flex;
  align-items: center;
}

/*----------------------------------------------------- Search Filters --------------------------------------------*/
.ClearFiltersContainer {
  clear: both;
  margin-top: -5px;
}

.ClearFiltersContainer a {
  font-size: 12px;
  color: #1f92c2;
  clear: both;
}

.FiltersLinkContainer {
  margin-left: 0;
  align-self: center;
  display: none;
  border-radius: 4px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.FiltersContainer h1 {
  display: none;
}

.FiltersLinkContainer .Button {
  margin-bottom: 0px;
  margin-right: 0px;
}

.Button.BlueSettingsIcon {
  background-image: url(/assets/images/icons/icon_settings_blue.svg);
}

.HideFilters {
  display: none;
}

.FiltersButtonsContainer {
  margin-top: 30px;
  margin-bottom: 30px;
  display: none;
}

/*----------------------------------------------------- Misc --------------------------------------------*/
.SearchButton {
  float: left;
  height: 30px;
  width: 30px;
  background-color: #272b35;
  margin-top: 22px;
  margin-left: -10px;
  margin-right: 10px;
  background-image: url(/assets/images/icons/icon_search_white.svg);
  background-size: 20px auto;
  background-repeat: no-repeat;
  background-position: center;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

#MobileSort {
  display: none;
}

.LinkedQuote .CellContainer:first-child {
  padding-right: 40px;
  position: relative;
}

.LinkedQuote .CellContainer:first-child:after {
  position: absolute;
  content: '';
  display: inline-block;
  height: 15px;
  width: 16px;
  margin-left: 15px;
  background-image: url(/assets/images/icons/icon_batch_grey.svg);
  background-repeat: no-repeat;
  background-size: 15px auto;
  right: 10px;
  top: 50%;
  margin-top: -8px;
}

.CellContainer.Milestone img {
  height: 15px;
}

.GridPopup table {
  width: 100%;
}

.GridPopup td {
  font-size: 12px;
  padding: 5px 20px 5px 0px;
  vertical-align: top;
}

.GridPopup td:first-child {
  width: 135px;
}

.GridPopupHeader td {
  border-bottom: 1px solid #c2c2c2;
}

.CellContainer.UserType img {
  width: 20px;
}

.Affiliate {
  color: #999999;
}

.AssetDetail {
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.AssetClass {
  color: #999999;
  font-size: 12px;
}

.Funder {
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

/*------------------------------------------------------ Tags ----------------------------------------------------- */
.TagContainer {
  color: #ffffff;
  padding: 2px 8px;
  margin-right: 10px;
  border-radius: 8px;
  white-space: nowrap;
  margin-bottom: 5px;
  display: inline-block;
  font-size: 14px;
}

.TagContainer.Default {
  background-color: #22252e;
}

.TagContainer.Success {
  background-color: #b6cf27;
}

.TagContainer.Caution {
  background-color: #ffc600;
}

.TagContainer.Failure {
  background-color: #c42b2b;
}

.TagContainer.New {
  background-color: #0096db;
}

.TagContainer.Waiting {
  background-color: #ea74dd;
}

.TagContainer.Draft {
  background-color: #2bbab8;
}

.TagContainer.Closed {
  background-color: #888888;
}
