@use 'styles/core_theme';

@use 'ngx-toastr/toastr';

@use '@angular/cdk/overlay-prebuilt.css';

/* Theme styles */

@use 'scss/datepicker.scss';

@use 'scss/global.scss';
@use 'scss/global_responsive.scss';

@use 'scss/grids.scss';
@use 'scss/grids_responsive.scss';

@use 'scss/forms.scss';
@use 'scss/forms_responsive.scss';

@use 'scss/search.scss';
@use 'scss/search_responsive.scss';

@use 'scss/detail.scss';
@use 'scss/detail_responsive.scss';

@use '@ng-select/ng-select/themes/default.theme.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

.sub-header {
  padding: 20px;
  border-top: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;

  span {
    margin: 10px 10px 10px 0;
    padding: 7px 10px;
    background-color: #ffc107;
  }
}

.button-separator {
  padding: 20px;
  border-bottom: 1px solid #c2c2c2;
}

// remove notch from inputs
.mdc-notched-outline__notch {
  border-right: none !important;
}
