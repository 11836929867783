/*----------------------------------------------------- Psuedo --------------------------------------------*/
* {
  font-family: Open Sans;
  box-sizing: border-box;
  font-weight: 300;
  font-size: 14px;
  outline: none;
}
html {
  height: 100%;
}
body {
  margin: 0;
  height: 100%;
  background-color: #eeeeee;
}
a {
  text-decoration: none;
  color: #1f92c2;
  cursor: pointer;
}
a:hover {
  text-decoration: none;
  color: #10147e;
}
h1 {
  font-family: 'Roboto', sans-serif;
  font-size: 21px;
  font-weight: 700;
  margin: 0;
}
h2 {
  font-size: 18px;
  font-weight: 300;
  margin: 0;
}
h3 {
  font-size: 14px;
  font-weight: 300;
  margin: 0;
}
strong {
  font-weight: 400;
}
h1 span,
h2 span,
h3 span {
  font-size: inherit;
  font-weight: inherit;
}
/*----------------------------------------------------- General Containers --------------------------------------------*/
.MainContainer {
  width: 100%;
  margin: auto;
  position: absolute;
  background-color: #eeeeee;
  min-height: 100%;
}
.ContentContainer {
  max-width: 1400px;
  margin: auto;
  background-color: #ffffff;
  position: relative;
}
.PageContentContainer {
  flex: 1;
  min-width: 250px;
}
.PanelContainer {
  position: relative;
  padding-bottom: 100px;
  /*overflow: hidden;*/
  min-height: 500px;
}
.SectionContainer {
  position: relative;
}
.SectionContainer_VerticalPadding_Condensed {
  padding-top: 15px;
  padding-bottom: 15px;
}
.SectionContainer_VerticalPadding {
  padding-top: 30px;
  padding-bottom: 30px;
}
.SectionContainer_VerticalPadding_Expanded {
  padding-top: 60px;
  padding-bottom: 60px;
}
.SectionContainer_VerticalTopPadding_Condensed {
  padding-top: 15px;
}
.SectionContainer_VerticalTopPadding {
  padding-top: 30px;
}
.SectionContainer_VerticalTopPadding_Expanded {
  padding-top: 60px;
}
.SectionContainer_VerticalBottomPadding_Condensed {
  padding-bottom: 15px;
}
.SectionContainer_VerticalBottomPadding {
  padding-bottom: 30px;
}
.SectionContainer_VerticalBottomPadding_Expanded {
  padding-bottom: 60px;
}
.SectionContainer_VerticalPadding_Grid {
  padding-top: 30px;
  padding-bottom: 30px;
}
.SectionContainer_VerticalPadding_Grid_Condensed {
  padding-top: 15px;
  padding-bottom: 15px;
}
.SectionContainer_HorizontalPadding {
  padding-left: 50px;
  padding-right: 50px;
}
.PageMask {
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: none;
}
/*----------------------------------------------------- General --------------------------------------------*/
.MainColor {
  color: #0096db;
}
.Clear {
  clear: both;
}
.ClearLeft {
  clear: left;
}
.FloatRight {
  float: right;
}
.Divider {
  border-top: 1px solid #c2c2c2;
}
.BottomDivider {
  border-bottom: 1px solid #c2c2c2;
}
.DividerArrow {
  position: relative;
}
.DividerArrow::before {
  content: '';
  position: absolute;
  width: 12px;
  height: 12px;
  border-style: solid;
  border-width: 1px 1px 0 0;
  border-color: #c2c2c2;
  top: -7px;
  left: 30px;
  transform: rotate(-45deg);
  background-color: #ffffff;
}
.HoverEffect:hover {
  cursor: pointer;
}
.Link {
  text-decoration: none;
  color: #1f92c2;
  cursor: pointer;
}
.Link:hover {
  text-decoration: none;
  color: #10147e;
}
.ParagraphHeader {
  margin-bottom: 10px;
}
.ParagraphHeader.WithContainer {
  background-color: #f5f5f5;
  padding: 5px 10px;
  margin-left: -10px;
}
h1.YellowHighlight,
h2.YellowHighlight {
  padding-left: 10px;
  padding-right: 10px;
  background-color: #ffc107;
  display: inline-block;
  padding-top: 5px;
  padding-bottom: 5px;
}
h1.GreyHighlight,
h2.GreyHighlight {
  padding-left: 10px;
  padding-right: 10px;
  background-color: #6c757d;
  color: #ffffff;
  display: inline-block;
  padding-top: 5px;
  padding-bottom: 5px;
}
.ContainerHeader {
  background-color: #f5f5f5;
  padding: 5px;
}
.Flex {
  display: flex;
  flex-wrap: wrap;
}
.Hidden {
  display: none !important;
}
.DisableInput input[type='text'],
.DisableInput select,
.DisableInput textarea {
  pointer-events: none;
  background-color: #f5f5f5;
}
/*----------------------------------------------------- Page Loader --------------------------------------------*/
// .PageLoadContainer {
//   position: fixed;
//   top: 0px;
//   left: 0px;
//   bottom: 0px;
//   right: 0px;
//   height: 100%;
//   width: 100%;
//   background-color: rgba(0, 0, 0, 0.1);
//   z-index: 5000;
//   display: none;
// }
// .PageLoadContainer::after {
//   content: '';
//   position: absolute;
//   width: 80px;
//   height: 80px;
//   top: 50%;
//   left: 50%;
//   margin-top: -40px;
//   margin-left: -40px;
//   background-image: url(/assets/images/global/loader.png);
//   background-size: contain;
//   background-repeat: no-repeat;
//   background-position: center;
//   -webkit-animation: spin 2s linear infinite; /* Safari */
//   animation: spin 0.7s linear infinite;
// }
// @-webkit-keyframes spin {
//   0% {
//     -webkit-transform: rotate(0deg);
//   }
//   100% {
//     -webkit-transform: rotate(360deg);
//   }
// }
// @keyframes spin {
//   0% {
//     transform: rotate(0deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// }
/*----------------------------------------------------- Notification Banner --------------------------------------------*/
.NotificationBannerContainer {
  padding: 10px 50px;
  color: #ffffff;
  display: none;
}
.NotificationBannerContainer.Success {
  background-color: #9cad3c;
}
.NotificationBannerContainer.Warning {
  background-color: #ffc107;
}
.NotificationBannerContainer.Failure {
  background-color: #da4b4b;
}
.NotificationBannerContainer.Info {
  background-color: #272b35;
}
/*----------------------------------------------------- Affiliate Banner --------------------------------------------*/
.AffiliateBannerContainer {
  background-color: #22252e;
  color: #ffffff;
  height: 50px;
  line-height: 50px;
}
.AffiliateBanner {
  width: 1400px;
  margin: auto;
}
/*----------------------------------------------------- Header --------------------------------------------*/
.HeaderContainer {
  position: relative;
}
.LogoContainer {
  float: left;
  margin-left: 50px;
  display: flex;
  align-items: center;
  height: 110px;
}
.LogoContainer img {
  display: block;
  height: 60px;
}
.PoweredBy {
  position: relative;
}
.PoweredBy::after {
  content: '';
  position: absolute;
  height: 17px;
  width: 250px;
  bottom: 8px;
  background-size: 150px auto;
  background-position: left center;
  background-repeat: no-repeat;
}
.PoweredBy.Talk::after {
  background-image: url(/assets/images/global/poweredby_the-anything-loan-kompany.svg);
}
.PoweredBy.Netloan::after {
  background-image: url(/assets/images/global/poweredby_netloan-funding.svg);
}
/*----------------------------------------------------- Page Header --------------------------------------------*/
.PageHeader {
  padding-top: 10px;
  padding-bottom: 10px;
}
.PageHeader h1,
.PageHeader h2 {
  padding-top: 5px;
  padding-bottom: 5px;
  display: inline-block;
  margin-right: 30px;
}
.PageHeaderLinkContainer {
  display: inline-block;
  float: right;
  padding-top: 8px;
  padding-bottom: 8px;
}
/*----------------------------------------------------- Sub Menu --------------------------------------------*/
.SubMenuContainer {
  background-color: #f5f5f5;
}
/*----------------------------------------------------- Sub Header --------------------------------------------*/
.SubHeaderContainer {
  display: flex;
  flex-wrap: wrap;
}
.SubHeader {
  flex: 1;
  display: flex;
  align-items: center;
}
.SubHeaderButton .Button {
  margin-bottom: 0px;
}
/*----------------------------------------------------- Main Menu --------------------------------------------*/
.MainMenuContainer {
  float: right;
}
.MainMenu {
  margin: 0px;
  padding: 0px;
}
.MainMenu > li {
  list-style: none;
  float: left;
  margin: 0px;
  padding: 0px;
}
.MainMenu > li > a {
  display: block;
  padding: 0px 30px;
  height: 110px;
  line-height: 110px;
  color: #000000;
  text-decoration: none;
  font-size: 14px;
}
.MainMenu > li:hover > a {
  background-color: #22252e;
  color: #ffffff;
  cursor: pointer;
}
.MainMenu > li > a.Active {
  background-color: #0096db;
  color: #ffffff;
}
.MainMenu > li > a.Active:hover {
  background-color: #22252e;
  color: #ffffff;
}
/*----------------------------------------------------- Main Menu - Dropdown Menu --------------------------------------------*/
.HasDropdown {
  position: relative;
}
.HasDropdown > a {
  padding-right: 40px !important;
}
.HasDropdown > a > span::after {
  border-style: solid;
  border-color: #000000;
  border-width: 1px 1px 0 0;
  content: '';
  display: inline-block;
  height: 5px;
  position: relative;
  width: 5px;
  top: -4px;
  left: 15px;
  transform: rotate(135deg);
}
.HasDropdown.Active > a > span::after {
  border-color: #ffffff;
}
.HasDropdown:hover > a > span::after {
  border-color: #ffffff;
}
.DropdownMenuContainer {
  display: none;
  position: absolute;
  top: 100%;
  z-index: 100;
  width: 200px;
  padding: 15px 0px;
  background-color: #22252e;
}
.RightDropdownMenuContainer {
  right: 0px;
}
.HasDropdown:hover .DropdownMenuContainer {
  display: inline;
}
.DropdownMenu {
  padding: 0;
  margin: 0;
}
.DropdownMenu li {
  float: none;
  width: 100%;
  list-style: none;
}
.DropdownMenu li a {
  padding: 7px 25px;
  color: #ffffff;
  background-color: #22252e;
  width: 100%;
  display: block;
}
.DropdownMenu li:hover a {
  color: #ffffff;
  background-color: #0096db;
}
/*------------------------------------------------------ Mobile Menu ----------------------------------------------------- */
.MobileMenuIcon {
  position: absolute;
  top: 40px;
  right: 0;
  padding: 5px 30px;
  cursor: pointer;
  display: none;
  z-index: 1000;
}
.MobileMenuIcon img {
  width: 25px;
}
.MobileMenuContainer {
  background-color: #22252e;
  position: absolute;
  overflow-y: auto;
  top: 0px;
  right: 0px;
  bottom: 0px;
  width: 260px;
  background-repeat: repeat-y;
  background-position: left;
  display: none;
}
.MobileMenu {
  padding: 0;
  margin: 0;
  margin-top: 30px;
}
.MobileMenu li {
  list-style: none;
  text-align: right;
  padding-top: 7px;
  padding-bottom: 7px;
}
.MobileMenu li h1 {
  width: 100%;
  font-size: 24px;
  color: #ffffff !important;
  position: relative;
  text-decoration: none;
  font-weight: 300;
  padding-right: 50px;
  padding-left: 20px;
  display: block;
}
.MobileMenu li a {
  width: 100%;
  font-size: 16px;
  color: #ffffff !important;
  cursor: pointer;
  position: relative;
  text-decoration: none;
  font-weight: 300;
  padding-right: 50px;
  padding-left: 20px;
  display: block;
}
.MobileMenu img {
  vertical-align: middle;
}
/*------------------------------------------------------ Mobile Menu Drawer ----------------------------------------------------- */
.MobileMenuDrawerContainer {
  background-color: #272b35;
  padding: 15px 0px;
  margin-top: 15px;
  display: none;
}
.MobileMenuDrawer {
  padding: 0;
  margin: 0;
}
.MobileMenuDrawer li {
  list-style: none;
  text-align: right;
  padding-top: 7px;
  padding-bottom: 7px;
}
.MobileMenuDrawer li a {
  font-size: 15px;
  color: #ffffff !important;
  text-decoration: none;
  font-weight: 300;
  padding-left: 20px;
  padding-right: 50px;
  display: block;
}
.HasDrawer {
}
.HasDrawer::after {
  border-style: solid;
  border-color: #ffffff;
  border-width: 1px 1px 0 0;
  content: '';
  display: inline-block;
  height: 6px;
  position: absolute;
  width: 6px;
  right: 25px;
  top: 8px;
  transform: rotate(-135deg);
}
.HasDrawer.ExpandedArrow::after {
  border-style: solid;
  border-color: #ffffff;
  border-width: 1px 1px 0 0;
  content: '';
  display: inline-block;
  height: 6px;
  position: absolute;
  width: 6px;
  right: 25px;
  top: 6px;
  transform: rotate(135deg);
}
.HasDrawer.AvailableActions {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 1px dashed #4e4e4f;
}
.HasDrawer.AvailableActions::after {
  top: 18px;
}
.HasDrawer.AvailableActions.ExpandedArrow::after {
  top: 16px;
}
/*------------------------------------------------------ Side Menu ----------------------------------------------------- */
.PageContainer_WithSideMenu {
  display: flex;
}
.SideMenuContentContainer {
  flex: 1;
  min-width: 250px;
  min-height: 700px;
}
.SideMenuContainer {
  border-right: 1px solid #c2c2c2;
  width: 225px;
  transition: width 0.1s;
  box-sizing: border-box;
}
.SideMenu {
  padding: 0;
  margin: 0;
}

.SideMenu li {
  list-style: none;
  margin: 0px;
  padding-left: 50px;
  padding-right: 0px;
  font-size: 14px;
  height: 50px;
  line-height: 50px;
  background-position: left 15px center;
  background-repeat: no-repeat;
  background-size: 17px auto;
}
.SideMenuTab:hover {
  background-color: #22252e;
  color: #ffffff;
  cursor: pointer;
}
.SideMenuTab.Active {
  background-color: #0096db;
  color: #ffffff;
  position: relative;
}
.SideMenuTab.Active:hover {
  background-color: #22252e;
  color: #ffffff;
}
.SideMenuTab.Complete .SideMenuLabel {
  position: relative;
}
.SideMenuTab.Complete .SideMenuLabel::after {
  content: '';
  position: absolute;
  height: 16px;
  width: 16px;
  background-color: #9cad3c;
  right: 20px;
  top: 50%;
  margin-top: -8px;
  border-radius: 50%;
  z-index: 10;
}
.SideMenuTab.Complete .SideMenuLabel::before {
  position: absolute;
  content: '';
  display: block;
  width: 3px;
  height: 6px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  right: 26px;
  top: 50%;
  margin-top: -5px;
  z-index: 20;
}
.SideMenuHeader {
  padding-left: 20px !important;
  background-color: #ffffff !important;
  color: #000000 !important;
  border-bottom: 1px solid #c2c2c2;
  position: relative;
}
.SideMenuLabel {
  width: 170px;
  padding-right: 20px;
  height: 50px;
  line-height: 50px;
}
.SideMenuToggleIcon {
  position: absolute;
  right: 20px;
  top: 50%;
  margin-top: -7px;
  cursor: pointer;
}
.SideMenuToggleIcon div {
  width: 15px;
  height: 2px;
  background-color: #000000;
  margin-bottom: 4px;
}
.SideMenuToggleIcon.ExpandArrow::before {
  position: absolute;
  right: -8px;
  border-style: solid;
  border-color: #000000;
  border-width: 1px 1px 0 0;
  content: '';
  display: inline-block;
  height: 5px;
  width: 5px;
  top: 50%;
  margin-top: -5px;
  transform: rotate(45deg);
}
.SideMenuToggleIcon.CollapseArrow::before {
  position: absolute;
  left: -8px;
  border-style: solid;
  border-color: #000000;
  border-width: 1px 1px 0 0;
  content: '';
  display: inline-block;
  height: 5px;
  width: 5px;
  top: 50%;
  margin-top: -5px;
  transform: rotate(-135deg);
}
.SideMenuToggleIcon:hover div {
  background-color: #0096db;
}
.SideMenuToggleIcon.CollapseArrow:hover:before {
  border-color: #0096db;
}
.SideMenuToggleIcon.ExpandArrow:hover:before {
  border-color: #0096db;
}
// TODO: This will affect more than just the app-app page.
// .SideMenuToggleIcon.ExpandArrow {
//   display: none;
// }
/*------------------------------------------------------ Drawer ----------------------------------------------------- */
.GlobalPanelDrawer {
  position: fixed;
  top: 0px;
  right: -40%;
  bottom: 0px;
  background-color: #ffffff;
  width: 40%;
  z-index: 1001;
  box-shadow: -8px 0 6px -6px #c2c2c2;
  border-left: 1px solid #c2c2c2;
  display: none;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}
.PanelDrawer {
  position: fixed;
  top: 0px;
  right: -40%;
  bottom: 0px;
  background-color: #ffffff;
  width: 40%;
  z-index: 1001;
  box-shadow: -8px 0 6px -6px #c2c2c2;
  border-left: 1px solid #c2c2c2;
  display: none;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}
.LayeredPanelDrawer {
  position: fixed;
  top: 0px;
  right: -40%;
  bottom: 0px;
  background-color: #ffffff;
  width: 40%;
  z-index: 1002;
  box-shadow: -8px 0 6px -6px #c2c2c2;
  border-left: 1px solid #c2c2c2;
  display: none;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}
.DrawerHeader {
  padding: 20px 50px;
}
.InlineHighlight {
  background-color: #ffc107;
  padding-left: 5px;
  padding-right: 5px;
}
.DrawerSubSection {
  display: none;
}
.DrawerSegmentContainer {
}
.PanelDrawerSubMenu {
  padding: 0;
  margin: 0;
}
.PanelDrawerSubMenuTab {
  float: left;
  list-style: none;
  padding: 10px 20px;
  font-size: 14px;
  background-color: #f5f5f5;
}
.PanelDrawerSubMenuTab:first-child {
}
.PanelDrawerSubMenuTab:last-child {
}
.PanelDrawerSubMenuTab:hover {
  cursor: pointer;
  background-color: #f5f5f5;
}
.PanelDrawerSubMenuTab.Active {
  background-color: #0096db;
  color: #ffffff;
}
.CloseDrawerButton {
  height: 30px;
  width: 30px;
  position: absolute;
  right: 20px;
  top: 15px;
}
.CloseDrawerButton::before {
  content: 'x';
  position: absolute;
  font-size: 21px;
  font-weight: 700;
  text-align: center;
  top: 50%;
  margin-top: -14px;
  left: 50%;
  margin-left: -4px;
  color: #000000;
}
/* ---------------------------------------- Available Actions Menu ---------------------------------------- */
.AvailableActionsMenuContainer {
  background-color: #ffffff;
  color: #000000;
  font-size: 14px;
  position: absolute;
  top: 50%;
  margin-top: -22px;
  right: 25px;
  line-height: 33px;
  height: 35px;
  z-index: 90;
}
.AvailableActionsMenuContainer:hover {
  background-color: #ffffff;
  height: auto;
  cursor: pointer;
  box-shadow:
    0 0 0 1px rgba(111, 119, 130, 0.15),
    0 5px 20px 0 rgba(21, 27, 38, 0.08);
  border-radius: 4px;
}
.AvailableActionsButton {
  padding: 5px 55px 0px 20px;
  background-image: url(/assets/images/icons/icon_lightning_grey.svg);
  background-position: right 35px top 14px;
  background-repeat: no-repeat;
  background-size: 7px auto;
  position: relative;
  text-align: right;
}
.AvailableActionsButton::after {
  position: absolute;
  content: '';
  display: inline-block;
  border-color: #6b6b6b;
  border-style: solid;
  border-width: 1px 1px 0 0;
  height: 4px;
  width: 4px;
  transform: rotate(135deg);
  top: 50%;
  margin-top: -1px;
  right: 20px;
}
.AvailableActionsDropdownContainer {
  display: none;
}
.AvailableActionsMenuContainer:hover .AvailableActionsDropdownContainer {
  display: block;
}
.AvailableActionsMenu {
  padding: 0;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 15px;
}
.AvailableActionsMenu {
  list-style: none;
}
.AvailableActionsMenu li {
  padding-left: 20px;
  padding-right: 20px;
  text-align: right;
}
.AvailableActionsMenu li:hover {
  background-color: #0096db;
  color: #ffffff;
}
.AvailableActionsMobileMenuContainer {
  display: none;
}
/*------------------------------------------------------ Misc ----------------------------------------------------- */
.VersionContainer {
  position: absolute;
  bottom: 0px;
  right: 0px;
  font-size: 10px;
  color: #d2d2d2;
  transform: rotate(90deg);
  transform-origin: top right;
  padding-top: 10px;
}
.Hover:hover {
  cursor: pointer;
}
.HasBackArrow {
  position: relative;
}
.HasBackArrow::before {
  position: absolute;
  left: -10px;
  border-style: solid;
  border-color: #1f92c2;
  border-width: 1px 1px 0 0;
  content: '';
  display: inline-block;
  height: 5px;
  width: 5px;
  top: 50%;
  margin-top: -3px;
  transform: rotate(-135deg);
}
.HasBackArrow:hover:before {
  border-color: #000000;
}
.HasForwardArrow {
  position: relative;
}
.HasForwardArrow::before {
  position: absolute;
  right: -10px;
  border-style: solid;
  border-color: #1f92c2;
  border-width: 1px 1px 0 0;
  content: '';
  display: inline-block;
  height: 5px;
  width: 5px;
  top: 50%;
  margin-top: -3px;
  transform: rotate(45deg);
}
.HasForwardArrow:hover:before {
  border-color: #000000;
}
.HoverEffect {
  cursor: pointer;
}
.NoWrap {
  white-space: nowrap;
}
