@media (max-width: 1399px) {
}

@media (max-width: 991px) {
  /*----------------------------------------------------- General --------------------------------------------*/
  .SectionContainer_HorizontalPadding {
    padding-left: 30px;
    padding-right: 30px;
  }
  .SectionContainer_VerticalPadding_Grid {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  /*----------------------------------------------------- Header --------------------------------------------*/
  .LogoContainer {
    margin-left: 30px;
  }
  .PageHeaderLinkContainer {
    float: none;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 5px;
  }
  /*----------------------------------------------------- Main Menu --------------------------------------------*/
  .MainMenuContainer {
    display: none;
  }
  /*------------------------------------------------------ Mobile Menu ----------------------------------------------------- */
  .MobileMenuIcon {
    display: inline;
  }
  /*------------------------------------------------------ Drawer ----------------------------------------------------- */
  .PanelDrawer {
    right: -60%;
    width: 60%;
  }
  .DrawerHeader {
    padding: 20px 30px;
  }
}

@media (max-width: 767px) {
  /*------------------------------------------------------ Drawer ----------------------------------------------------- */
  .PanelDrawer {
    right: -100%;
    width: 100%;
    background-color: #f5f5f5;
  }
  /* ---------------------------------------- Available Actions Menu ---------------------------------------- */
  .AvailableActionsMenuContainer {
    display: none;
  }
  .AvailableActionsMobileMenuContainer {
    display: block;
  }
}

@media (max-width: 575px) {
  /*----------------------------------------------------- General --------------------------------------------*/
  .SectionContainer_HorizontalPadding {
    padding-left: 15px;
    padding-right: 15px;
  }
  /*----------------------------------------------------- Header --------------------------------------------*/
  .LogoContainer {
    margin-left: 15px;
    height: 90px;
  }
  .LogoContainer img {
    height: 50px;
  }
  .MobileMenuIcon {
    top: 30px;
  }
  /*------------------------------------------------------ Side Menu ----------------------------------------------------- */
  .PageContainer_WithSideMenu {
    flex-wrap: wrap;
  }
  .SideMenuContainer {
    width: 100%;
    overflow: scroll;
    border-bottom: 1px solid #c2c2c2;
    transition: all 0.3s;
    -webkit-overflow-scrolling: touch;
  }
  .SideMenu {
    display: inline-flex;
  }
  .SideMenuTab {
    display: inline-table;
    white-space: nowrap;
  }
  .SideMenuHeader {
    display: none;
  }
  .SideMenu li {
    float: left;
  }
  .SideMenuLabel {
    width: auto;
  }
  .SideMenuTab.Complete .SideMenuLabel {
    padding-right: 50px;
  }
  .SideMenuTab.Selected::after {
    display: none;
  }
  .SideMenuTab.Active:hover {
    background-color: #0096db;
    color: #ffffff;
  }
  /*------------------------------------------------------ Drawer ----------------------------------------------------- */
  .PanelDrawer {
    position: fixed;
    z-index: 1000;
  }
  .DrawerHeader {
    padding: 20px 15px;
  }
  /*------------------------------------------------------ Misc ----------------------------------------------------- */
  .HasBackArrow {
    margin-left: 10px;
  }
}

@media (max-width: 374px) {
}
