i * {
  box-sizing: border-box;
}
/*----------------------------------------------------- Form Containers --------------------------------------------*/
.FormContainer {
  display: flex;
  flex-wrap: wrap;
}
.InfoContainer {
  width: 100%;
  font-size: 14px;
  padding: 20px 30px 20px 60px;
  background-color: #f5f5f5;
  margin-bottom: 30px;
  background-image: url(/assets/images/forms/icon_info_grey.svg);
  background-size: 25px auto;
  background-repeat: no-repeat;
  background-position: left 20px center;
}
.InfoContainer p,
.InfoContainer a {
  font-size: 14px;
}
.InstructionContainer {
  width: 100%;
  font-size: 14px;
  padding: 20px 30px 20px 30px;
  background-color: #f5f5f5;
  margin-bottom: 30px;
}
.FormTile.Fixed {
  float: left;
  margin-bottom: 30px;
  padding-bottom: 5px;
  margin-right: 50px;
  width: 290px;
}
.FormTile.Full {
  float: none;
  margin-bottom: 30px;
  padding-bottom: 5px;
  width: 100%;
}
.ActiveFormTile {
  outline: none;
  border-color: #3ba9e8;
  box-shadow: 0 0 5px #3ba9e8;
}
.FormSectionContainer {
}
.FormTile.Fixed .FormSectionContainer {
}
.FormTile.Full .FormSectionContainer {
  width: 100%;
  clear: both;
}
.FieldComboBox {
  position: relative;
  float: left;
  margin-bottom: 10px;
}
.FormTile.Full .FieldComboBox {
  margin-right: 0px;
}
.DisclaimerContainer {
  width: 100%;
  font-size: 14px;
  padding: 0px 30px;
  background-color: #f5f5f5;
  margin-bottom: 30px;
}
/*----------------------------------------------------- Application Fields --------------------------------------------*/
.FieldLabel {
  font-size: 12px;
  margin-bottom: 5px;
}
.NoLabel {
  height: 1px;
}
.FullSizeFieldLabel {
  font-size: 14px;
  margin-bottom: 5px;
}
.FormSectionContainer input[type='text'],
.FormTile input[type='password'],
.FormTile input[type='number'] {
  height: 40px;
  font-size: 14px;
  border: 1px solid #c2c2c2;
  padding: 0px 10px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  line-height: 40px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.FormSectionContainer input[type='radio'] {
  height: 13px;
  width: 13px;
}
.FormSectionContainer select {
  height: 40px;
  font-size: 14px;
  border: 1px solid #c2c2c2;
  padding: 0px 0px 0px 10px;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  line-height: 40px;
  vertical-align: middle;
  -webkit-border-radius: 0px;
  -webkit-background-color: #ffffff;
  border-radius: 0px;
  background-color: #ffffff;
}
.FormSectionContainer textarea {
  border: 1px solid #c2c2c2;
  padding: 10px;
  font-size: 14px;
  font-family: 'Lato', sans-serif;
  font-weight: 300;
  resize: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.Field_RightAlign .FieldLabel,
.Field_RightAlign input[type='text'],
.Field_RightAlign .PsuedoLabel {
  text-align: right;
}
.FormTile input[type='text']:disabled,
.FormTile select:disabled,
.FormTile textarea:disabled {
  background-color: #f5f5f5;
}
.FormTile .PsuedoLabel input[type='text'],
.FormTile .PsuedoLabel input[type='text']:disabled {
  border: 1px solid transparent;
  padding: 0px;
}
.StandAloneField input[type='text'] {
  height: 40px;
  font-size: 14px;
  border: 1px solid #c2c2c2;
  padding: 0px 10px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  line-height: 40px;
}
input[type='text']:disabled {
  background-color: #f5f5f5;
}
/*----------------------------------------------------- Dynamic Field Sizes --------------------------------------------*/
.Full,
.FourFifths,
.TwoThirds,
.ThreeFifths,
.Half,
.TwoFifths .OneThird,
.OneFourth,
.OneFifth {
}
.FieldComboBox.Full {
  width: 100%;
}
.FieldComboBox.FourFifths {
  width: 80%;
}
.FieldComboBox.TwoThirds {
  width: 66.66%;
}
.FieldComboBox.ThreeFifths {
  width: 60%;
}
.FieldComboBox.Half {
  width: 50%;
}
.FieldComboBox.TwoFifths {
  width: 40%;
}
.FieldComboBox.OneThird {
  width: 33.33%;
}
.FieldComboBox.OneFourth {
  width: 25%;
}
.FieldComboBox.OneFifth {
  width: 20%;
}
.FieldComboBox.Tight {
  margin-left: -1px;
}
.Field_Standard {
  width: 100%;
}
/*----------------------------------------------------- Fixed Field Sizes --------------------------------------------*/
.ExtraLarge,
.LargePlus,
.Large,
.MediumPlus,
.Medium,
.Small,
.Tiny {
}
.FieldComboBox.ExtraLarge {
  width: 280px;
}
.FieldComboBox.LargePlus {
  width: 220px;
}
.FieldComboBox.Large {
  width: 200px;
}
.FieldComboBox.MediumPlus {
  width: 160px;
}
.FieldComboBox.Medium {
  width: 135px;
}
.FieldComboBox.Small {
  width: 110px;
}
.FieldComboBox.Tiny {
  width: 70px;
}
/*----------------------------------------------------- Field Spacing --------------------------------------------*/
.Field_Standard input[type='text'],
.Field_Standard input[type='password'],
.Field_Standard input[type='number'],
.Field_Standard .PsuedoLabel,
.Field_Standard select,
.Field_Standard textarea {
  width: 100%;
}
.Field_Standard .MinimalRadioContainer label {
  margin-right: 0px;
}
.Field_Spaced input[type='text'],
.Field_Spaced input[type='password'],
.Field_Spaced input[type='number'],
.Field_Spaced .PsuedoLabel,
.Field_Spaced select,
.Field_Spaced textarea {
  width: calc(100% - 10px);
}
.Field_Spaced .MinimalRadioContainer label {
  margin-right: 10px;
}
/*----------------------------------------------------- Checkmarks --------------------------------------------*/
.Field_Checkbox {
  display: block;
  position: relative;
  padding-left: 40px;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.Field_Checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.Checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #ffffff;
  border: 1px solid #c2c2c2;
}
.Field_Checkbox:hover input ~ .Checkmark {
  background-color: #f5f5f5;
}
.Field_Checkbox input:checked ~ .Checkmark {
  background-color: #ffffff;
}
.Checkmark:after {
  content: '';
  position: absolute;
  display: none;
}
.Field_Checkbox input:checked ~ .Checkmark:after {
  display: block;
}
.Field_Checkbox .Checkmark:after {
  left: 3px;
  top: 1px;
  width: 4px;
  height: 8px;
  border: solid #000000;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
/*----------------------------------------------------- Radio Buttons --------------------------------------------*/
.RadioContainer {
  height: 40px;
  border: 1px solid #c2c2c2;
  float: left;
  margin-right: 10px;
  line-height: 40px;
  padding-left: 5px;
  padding-right: 10px;
  font-size: 14px;
  background-color: #ffffff;
}
.RadioContainer:hover {
  cursor: pointer;
}
.MinimalRadioContainer {
  float: left;
}
.MinimalRadioContainer label {
  height: 40px;
  border: 1px solid #c2c2c2;
  float: left;
  line-height: 40px;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 14px;
  background-color: #ffffff;
  margin-bottom: 10px;
}
.MinimalRadioContainer label:hover {
  cursor: pointer;
}
.MinimalRadioContainer input {
  display: none;
}
.MinimalRadioContainer input:checked + label {
  border: 1px solid #81a20f !important;
  -moz-box-shadow: 0 0 5px #81a20f;
  -webkit-box-shadow: 0 0 5px #81a20f;
  box-shadow: 0 0 5px #81a20f;
}
.CheckboxContainer {
  height: 25px;
  border: 1px solid #c2c2c2;
  float: left;
  margin-right: 10px;
  line-height: 25px;
  padding-left: 7px;
  padding-right: 12px;
  width: 90px;
}
/*----------------------------------------------------- Circle Radio Buttons --------------------------------------------*/
.CircleRadioContainer {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: none;
}
.CircleRadioContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.CircleRadioCheckmark {
  position: absolute;
  margin-top: 3px;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #ffffff;
  border: 2px solid #c2c2c2;
  border-radius: 50%;
}
.CircleRadioContainer:hover input ~ .CircleRadioCheckmark {
  background-color: #f5f5f5;
}
.CircleRadioContainer input:checked ~ .CircleRadioCheckmark {
  border: 2px solid #9cad3c;
}
.CircleRadioCheckmark:after {
  content: '';
  position: absolute;
  display: none;
}
.CircleRadioContainer input:checked ~ .CircleRadioCheckmark:after {
  position: absolute;
  content: '';
  display: block;
  width: 5px;
  height: 10px;
  border: solid #9cad3c;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  left: 50%;
  margin-left: -3px;
  top: 50%;
  margin-top: -7px;
}
.CircleRadioContainer .CircleRadioCheckmark:after {
}
/*----------------------------------------------------- Toggle Switches --------------------------------------------*/
.ToggleSwitch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 26px;
  margin-top: 5px;
}
.ToggleSwitch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.ToggleSlider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.ToggleSlider:before {
  position: absolute;
  content: '';
  height: 22px;
  width: 22px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .ToggleSlider {
  background-color: #7ca233;
}
input:focus + .ToggleSlider {
  box-shadow: 0 0 1px #7ca233;
}
input:checked + .ToggleSlider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
}
.ToggleSlider.Round {
  border-radius: 20px;
}
.ToggleSlider.Round:before {
  border-radius: 50%;
}
/*----------------------------------------------------- Help Prompts --------------------------------------------*/
.InlineFieldHelpPrompt {
  clear: both;
  font-size: 12px;
  padding-left: 30px;
  position: relative;
  width: 280px;
  padding-top: 5px;
  padding-bottom: 10px;
}
.InlineFieldHelpPrompt::before {
  content: '';
  width: 18px;
  height: 18px;
  position: absolute;
  background-image: url(/assets/images/forms/icon_help_grey.svg);
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: center;
  left: 0;
  margin-top: -9px;
  top: 50%;
}
.PopupHelpPrompt {
  position: relative;
}
.PopupHelpPromptIcon {
  width: 40px;
  height: 40px;
  position: absolute;
  background-image: url(/assets/images/forms/icon_help_grey.svg);
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: center;
  right: -30px;
  top: 50%;
  margin-top: -25px;
  cursor: pointer;
}
.PopupHelpPromptMessage {
  color: #ffffff;
  border-radius: 5px;
  z-index: 1000;
  position: absolute;
  padding: 15px;
  right: -30px;
  top: 50px;
  background-color: rgba(0, 0, 0, 0.8);
  font-size: 12px;
  width: calc(100% + 30px);
  display: none;
}
.PopupHelpPromptIcon:hover + .PopupHelpPromptMessage {
  display: block;
}
.PopupHelpPromptIcon.hover {
  background-image: url(/assets/images/forms/icon_close_blue.svg);
  background-size: 20px 20px;
}
.PopupHelpPromptIcon.hover + .PopupHelpPromptMessage {
  display: block;
}
.HeaderPopupHelpPrompt .PopupHelpPromptIcon {
  top: auto;
  bottom: 3px;
}
.PopupHelpPromptIcon:hover ~ .ValidationPromptContainer {
  display: none !important;
}
/*----------------------------------------------------- Validation --------------------------------------------*/
.ValidationError input,
.ValidationError select {
  background-color: #ffcccc;
}
.PopupValidationPrompt {
  position: relative;
}
.PopupValidationPrompt::before {
  content: '';
  width: 20px;
  height: 20px;
  position: absolute;
  background-image: url(/assets/images/forms/icon_info_red.svg);
  background-size: 20px auto;
  background-repeat: no-repeat;
  background-position: center;
  right: 10px;
  top: 50%;
  margin-top: -10px;
  cursor: pointer;
}
.ValidationPromptContainer {
  color: #ffffff;
  border-radius: 5px;
  z-index: 1000;
  position: absolute;
  padding: 15px;
  right: 0px;
  top: 40px;
  background-color: rgba(191, 49, 26, 0.9);
  font-size: 12px;
  min-width: 125px;
  width: 100%;
  display: none;
}
.PopupValidationPrompt:hover .ValidationPromptContainer {
  display: block;
}
/*----------------------------------------------------- Buttons Container --------------------------------------------*/
.ButtonContainer {
  position: relative;
}
.ButtonContainer.Success::after {
  content: 'Everything looks good';
  position: absolute;
  height: 20px;
  width: 300px;
  padding-left: 35px;
  left: 0px;
  bottom: -20px;
  background-image: url(/assets/images/forms/icon_checkmark-circle_green.svg);
  background-size: 20px auto;
  background-repeat: no-repeat;
  background-position: left center;
  color: #000000;
  line-height: 20px;
}
.ButtonContainer.Failure::after {
  content: 'Please fix errors above in red';
  position: absolute;
  height: 20px;
  width: 300px;
  padding-left: 35px;
  left: 0px;
  bottom: -20px;
  background-image: url(/assets/images/forms/icon_close_red.svg);
  background-size: 20px auto;
  background-repeat: no-repeat;
  background-position: left 0 center;
  color: #dd0000;
  line-height: 20px;
}
/*----------------------------------------------------- Default Buttons --------------------------------------------*/
.Button {
  font-size: 14px;
  float: left;
  background-color: #22252e;
  color: #ffffff;
  height: 40px;
  line-height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
}
.Button:hover {
  background-color: #040405;
  cursor: pointer;
}
.Button.Disabled {
  color: #f5f5f5;
}
.Button.HasRightIcon {
  padding-right: 45px;
  background-repeat: no-repeat;
  background-position: right 15px center;
  background-size: 16px auto;
}
.Button.HasLeftIcon {
  padding-left: 45px;
  background-repeat: no-repeat;
  background-position: left 15px center;
  background-size: 16px auto;
}
.Button.HasCenterIcon {
  padding-left: 22px;
  padding-right: 22px;
}
/*----------------------------------------------------- Alt Buttons --------------------------------------------*/
.Button.AltButton {
  background-color: #ffffff;
  border: 1px solid #c2c2c2;
  color: #1f92c2;
}
.Button.AltButton:hover {
  background-color: #f5f5f5;
}
.Button.GreyAltButton {
  background-color: #f5f5f5;
  border: 1px solid #c2c2c2;
  color: #1f92c2;
}
.Button.GreyAltButton:hover {
  background-color: #eeeeee;
}
/*----------------------------------------------------- Marketing Buttons --------------------------------------------*/
.Button.Marketing {
  background-color: #e70000;
  color: #ffffff;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
}
.Button.Marketing.AltButton {
  background-color: #ffffff;
  border: 1px solid #c2c2c2;
  color: #1f92c2;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
}
.Button.Marketing:hover {
  background-color: #b90000;
  cursor: pointer;
}
.Button.Marketing.AltButton:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}
/*----------------------------------------------------- Button Icons --------------------------------------------*/
.Button.RightArrowIcon::before {
  border-style: solid;
  border-width: 2px 2px 0 0;
  content: '';
  display: inline-block;
  height: 7px;
  position: absolute;
  width: 7px;
  top: 50%;
  margin-top: -3px;
  right: 20px;
  transform: rotate(45deg);
}
.Button.LeftArrowIcon::before {
  border-style: solid;
  border-width: 2px 2px 0 0;
  content: '';
  display: inline-block;
  height: 7px;
  position: absolute;
  width: 7px;
  top: 50%;
  margin-top: -3px;
  left: 20px;
  transform: rotate(-135deg);
}
.Button.CheckmarkIcon::after {
  position: absolute;
  content: '';
  display: block;
  width: 5px;
  height: 10px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  right: 17px;
  top: 50%;
  margin-top: -5px;
}
.Button.WhiteSendIcon {
  background-image: url(/assets/images/forms/icon_send_white.svg);
}
.Button.GreyTextIcon {
  background-image: url(/assets/images/forms/icon_text_grey.svg);
}
.Button.WhiteTextIcon {
  background-image: url(/assets/images/forms/icon_text_white.svg);
}
.Button.BlueTextIcon {
  background-image: url(/assets/images/forms/icon_text_blue.svg);
}
.Button.GreyEmailIcon {
  background-image: url(/assets/images/forms/icon_email_grey.svg);
}
.Button.WhiteEmailIcon {
  background-image: url(/assets/images/forms/icon_email_white.svg);
}
.Button.BlueEmailIcon {
  background-image: url(/assets/images/forms/icon_email_blue.svg);
}
.Button.GreyLinkIcon {
  background-image: url(/assets/images/forms/icon_link_grey.svg);
}
.Button.WhiteLinkIcon {
  background-image: url(/assets/images/forms/icon_link_white.svg);
}
.Button.BlueLinkIcon {
  background-image: url(/assets/images/forms/icon_link_blue.svg);
}
.Button.BlueOpenIcon {
  background-image: url(/assets/images/forms/icon_open_blue.svg);
}
.Button.GreyAddIcon {
  background-image: url(/assets/images/forms/icon_add_grey.svg);
}
.Button.WhiteAddIcon {
  background-image: url(/assets/images/forms/icon_add_white.svg);
}
.Button.BlueAddIcon {
  background-image: url(/assets/images/forms/icon_add_blue.svg);
}
/*----------------------------------------------------- Overlay Buttons --------------------------------------------*/
.OverlayButtonContainer {
  float: left;
  position: relative;
  height: 40px;
  line-height: 40px;
  overflow: hidden;
  width: 190px;
  display: block;
}
.HasOverlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 190px;
  z-index: 10;
}
.HasOverlay:hover .ButtonOverlay {
  display: block;
}
.ButtonOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f5f5f5;
  z-index: 5;
}
.ButtonOverlay .Button {
  font-size: 12px;
  float: left;
  background-color: #f5f5f5;
  color: #22252e;
  height: 40px;
  line-height: 40px;
  padding-left: 15px;
  padding-right: 40px;
  position: relative;
  margin-bottom: 10px;
  margin-right: 0px;
}
.ButtonOverlay .Button.HasRightIcon {
  padding-right: 45px;
}
.ButtonOverlay .Button:hover {
  color: #1f92c2;
}
.ButtonOverlay .Button.HasRightIcon.GreyTextIcon {
  background-image: url(/assets/images/forms/icon_text_grey.svg);
  background-repeat: no-repeat;
  background-position: right 10px center;
}
.ButtonOverlay .Button.HasRightIcon.GreyEmailIcon {
  background-image: url(/assets/images/forms/icon_email_grey.svg);
  background-repeat: no-repeat;
  background-position: right 10px center;
}
.ButtonOverlay .Button.HasRightIcon.GreyTextIcon:hover {
  background-image: url(/assets/images/forms/icon_text_blue.svg);
}
.ButtonOverlay .Button.HasRightIcon.GreyEmailIcon:hover {
  background-image: url(/assets/images/forms/icon_email_blue.svg);
}
/*----------------------------------------------------- Links Buttons --------------------------------------------*/
.Link.HasLeftIcon {
  padding-left: 15px;
  background-repeat: no-repeat;
  background-position: left 0px center;
  background-size: 16px auto;
  position: relative;
}
.Link.LeftArrowIcon::before {
  border-style: solid;
  border-width: 2px 2px 0 0;
  content: '';
  display: inline-block;
  height: 7px;
  position: absolute;
  width: 7px;
  top: 50%;
  margin-top: -4px;
  left: 0px;
  transform: rotate(-135deg);
}
/*----------------------------------------------------- Misc --------------------------------------------*/
.Clear {
  clear: both;
}
.ClearLeft {
  clear: left;
}
.FormSubHeader {
  width: 100%;
  clear: both;
  margin-bottom: 15px;
}
.FormSubHeader h2 {
  display: inline-block;
}
.AltFormSubHeader {
  visibility: hidden;
}
.FieldColumnBreak {
  margin-right: 91px;
}
.InlineField {
  float: left;
  margin-right: 10px;
}
.PermanentFieldLabel {
  position: relative;
}
.PermanentFieldLabel::after {
  content: attr(data-permanent-field-label);
  position: absolute;
  right: 10px;
  top: 0px;
  height: 40px;
  line-height: 40px;
  color: #777777;
}
.PsuedoLabel {
  height: 40px;
  font-size: 14px;
  border: 1px solid transparent;
  padding: 0px 10px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  line-height: 40px;
  padding-left: 0px;
}
.GroupFields {
  float: left;
}
.DisableInput input[type='text'],
.DisableInput select,
.DisableInput textarea {
  pointer-events: none;
  background-color: #f5f5f5;
}
.DisableLink {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  color: #aaaaaa;
}
.PasswordField {
  position: relative;
}
.TogglePasswordVisibility {
  position: absolute;
  height: 40px;
  width: 30px;
  background-color: #ffffff;
  right: 0px;
  top: 0px;
  background-position: center;
  background-size: 15px auto;
  background-repeat: no-repeat;
  border-right: 1px solid #c2c2c2;
  border-top: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;
}
.TogglePasswordVisibility.VisibleIcon {
  background-image: url(/assets/images/forms/icon_eye-closed_grey.svg);
}
.TogglePasswordVisibility.HiddenIcon {
  background-image: url(/assets/images/forms/icon_eye-open_grey.svg);
}
.TogglePasswordVisible:hover {
  cursor: pointer;
}
/*----------------------------------------------------- Upload Tile --------------------------------------------*/
.UploadTile {
  border: 1px dotted #c2c2c2;
  background-color: #f7fdff;
  position: relative;
  width: 100%;
  height: 100px;
  margin-bottom: 20px;
}
.UploadTile::before {
  position: absolute;
  content: 'Click or Drag To Upload';
  font-size: 12px;
  color: #1f92c2;
  top: 50%;
  margin-top: -25px;
  left: 0px;
  text-align: center;
  width: 100%;
  padding-top: 25px;
  background-image: url(/assets/images/icons/icon_add_blue.svg);
  background-position: center top;
  background-size: 18px;
  background-repeat: no-repeat;
  box-sizing: border-box;
  padding-left: 10px;
  padding-right: 10px;
}
