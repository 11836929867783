@media (max-width: 1399px) {
  /*----------------------------------------------------- Search Results Sub Header --------------------------------------------*/
  .NewItemContainer {
    border-left: none;
    padding: 20px 0px 20px 0px;
    order: 2;
  }
  .NewItemContainer .Button {
    margin-bottom: 0px;
  }
  /*----------------------------------------------------- Search Filters --------------------------------------------*/
  .FiltersContainer {
    order: 1;
    background-color: #ffffff;
    box-shadow:
      0 0 0 1px rgba(111, 119, 130, 0.15),
      0 5px 20px 0 rgba(21, 27, 38, 0.08);
    position: fixed;
    overflow-y: auto;
    top: 0px;
    right: -350px;
    bottom: 0px;
    width: 325px;
    background-repeat: repeat-y;
    background-position: left;
    padding-left: 30px;
    padding-right: 30px;
    z-index: 1000;
  }
  .FiltersContainer.Visible {
    display: block;
  }
  .FiltersLinkContainer {
    display: inline;
  }
  .FiltersContainer h1 {
    display: block;
  }
  .ClearFiltersContainer {
    display: none;
  }
  .FiltersButtonsContainer {
    display: block;
  }
  .FiltersContainer .FormTile,
  .FiltersContainer .FormSectionContainer,
  .FiltersContainer .FieldComboBox {
    width: 100%;
    min-width: 100%;
  }
  .FiltersContainer .Field_Large select,
  .FiltersContainer .Field_Medium select,
  .FiltersContainer .Field_Small input[type='text'],
  .FiltersContainer .Field_Small select {
    width: 100%;
  }
  /*----------------------------------------------------- Misc --------------------------------------------*/
  .AssetDetail {
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
@media (max-width: 1099px) {
}
@media (max-width: 991px) {
  /*----------------------------------------------------- Search Filters --------------------------------------------*/
  .GlobalSearchContainer {
    padding: 10px 30px 6px 60px;
    background-position: left 30px center;
  }
  /*----------------------------------------------------- Search Results Sub Header --------------------------------------------*/
  .SearchResultsSubHeader {
    border-bottom: 1px solid #c2c2c2;
  }
  /*----------------------------------------------------- Search Filters --------------------------------------------*/
  #MobileSort {
    display: inline;
  }
  /*----------------------------------------------------- Misc --------------------------------------------*/
  .CellContainer.UserType {
    margin-bottom: 5px;
  }
  .CellContainer.Milestone img {
    margin-bottom: -2px;
  }
  .AssetDetail {
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
@media (max-width: 767px) {
}
@media (max-width: 575px) {
  /*----------------------------------------------------- Search Bar --------------------------------------------*/
  .GlobalSearchContainer {
    padding: 10px 15px 6px 45px;
    background-position: left 15px center;
  }
  /*----------------------------------------------------- Search Results Tabs --------------------------------------------*/
  .SearchResultsTab:hover {
    background-color: #0096db;
  }
}
