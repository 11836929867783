@media screen and (max-width: 991px) {}
@media screen and (max-width: 767px) {
  /*----------------------------------------------------- Form Containers --------------------------------------------*/
  .FormTile {
    width: inherit;
  }
  .FormSectionContainer {}
  /*----------------------------------------------------- Help Prompts --------------------------------------------*/
  .PopupHelpPromptIcon:hover + .PopupHelpPromptMessage {
    display: none;
  }
  .PopupHelpPromptIcon.hover + .PopupHelpPromptMessage {
    display: block;
  }
  /*----------------------------------------------------- Misc --------------------------------------------*/
  .MiddleColumn {
    min-height: inherit;
  }
}
@media screen and (max-width: 575px) {
  /*----------------------------------------------------- Form Containers --------------------------------------------*/
  .FormTile {
    min-width: inherit;
    margin-right: 0px !important;
    width: 100% !important;
  }
  .FormSectionContainer {
    margin-right: 0px;
    width: 100%;
  }
  .FieldComboBox {
    margin-right: 0px;
    margin-bottom: 10px;
  }
  .AltFormSubHeader {
    display: none;
  }
  /*----------------------------------------------------- Application Fields --------------------------------------------*/
  .FieldLabel {
    font-size: 13px;
  }
  .FormSectionContainer input[type=text], .FormSectionContainer input[type=password] {
    font-size: 16px;
    height: 40px;
    line-height: 40px;
  }
  .FormSectionContainer select {
    font-size: 16px;
    height: 40px;
    line-height: 40px;
  }
  .FormSectionContainer textarea {
    font-size: 16px;
  }
  .FieldColumnBreak {
    margin-right: 0px;
  }
  .RadioContainer {
    height: 40px;
    line-height: 40px;
  }
  .MinimalRadioContainer label {
    height: 40px;
    line-height: 40px;
  }
  .PsuedoLabel {
    font-size: 14px;
  }
  /*----------------------------------------------------- Dynamic Field Sizes --------------------------------------------*/
  .Field_Spaced input[type=text], .Field_Spaced input[type=password], .Field_Spaced input[type=number], .Field_Spaced .PsuedoLabel, .Field_Spaced select, .Field_Spaced textarea {
    width: 100%;
  }
  /*----------------------------------------------------- Help Prompts --------------------------------------------*/
  .PopupHelpPrompt {
    width: calc(100vw - 30px);
  }
  .PopupHelpPromptIcon {
    right: 0px;
  }
  .PopupHelpPrompt.Select .PopupHelpPromptIcon {
    right: 30px;
  }
  .PopupHelpPromptMessage {
    top: 50px;
    width: 100%;
    right: 0px;
  }
  .PopupValidationPrompt::after {
    top: 50px;
  }
  /*----------------------------------------------------- Buttons --------------------------------------------*/
  .Button {
    margin-right: 5px;
  }
  .Button.HasRightIcon.OmitMobileIcon {
    padding-right: 20px;
    background-image: none;
  }
  .Button.OmitMobileIcon::before, .Button.OmitMobileIcon::after {
    display: none;
  }
  .Button.HasLeftIcon.OmitMobileIcon {
    padding-left: 20px;
    background-image: none;
  }
  /*----------------------------------------------------- Misc --------------------------------------------*/
  .PermanentFieldLabel::after {
    height: 40px;
    line-height: 40px;
  }
  .TogglePasswordVisibility {
    height: 40px;
  }
  .MobileLeftMargin {
    margin-left: 10px;
  }
}
@media screen and (max-width:400px) {}