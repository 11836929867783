@use '@angular/material' as mat;
@use 'styles_theme-colors' as theme-colors;

html {
  @include mat.theme((
    color: (
      primary: theme-colors.$primary-palette,
      tertiary: theme-colors.$tertiary-palette,
      theme-type: light,
    ),
    typography: (
      plain-family: Open Sans,
      brand-family: Open Sans,
      bold-weight: 900,
      medium-weight: 500,
      regular-weight: 300,
    ),
    density: -2
  ));

  @include mat.form-field-overrides((
    filled-container-shape: 0,
    outlined-container-shape: 1px,
  ));

  @include mat.button-overrides((
    filled-container-shape: 4px,
    outlined-container-shape: 4px,
    filled-container-height: 40px,
    outlined-container-height: 40px,
  ));


  @include mat.datepicker-overrides((
    calendar-container-background-color: white,
    calendar-container-shape: 16px,
    calendar-container-elevation-shadow: 4.0px 8.0px 8.0px hsl(0deg 0% 0% / 0.38),
  ));

  @include mat.select-overrides((
    panel-background-color: white,
    container-elevation-shadow: 4.0px 8.0px 8.0px hsl(0deg 0% 0% / 0.38),
  ));

  @include mat.button-toggle-overrides((
    shape: 0,
    height: 38px,
    divider-color: #c2c2c2,
  ));

  @include mat.stepper-overrides((
    container-color: white,
    header-hover-state-layer-color: white,
    header-focus-state-layer-color: white
  ));

  @include mat.autocomplete-overrides((
    background-color: white,
  ));

  @include mat.menu-overrides((
    container-color: white,
    item-leading-spacing: 20px,
    item-trailing-spacing: 20px,
    item-hover-state-layer-color: #0096db,
  ));

  @include mat.chips-overrides((
    elevated-selected-container-color: #0096db,
    selected-label-text-color: white,
    with-icon-selected-icon-color: white,
  ));

  @include mat.table-overrides((
    background-color: white,
    row-item-container-height: 60px,
  ));

  @include mat.slide-toggle-overrides((
    selected-track-color: #7ca233,
    unselected-track-color: #ccc,
    selected-focus-track-color: #7ca233,
    unselected-focus-track-color: #ccc,
    selected-track-outline-color: #7ca233,
    track-outline-color: #ccc,
    selected-icon-size: 0,
    unselected-icon-size: 0,
    selected-handle-color: white,
    unselected-handle-color: white,
    selected-hover-handle-color: white,
    unselected-hover-handle-color: white,
    unselected-hover-track-color: #ccc,
    selected-hover-track-color: #7ca233,
    selected-focus-handle-color: white,
    unselected-focus-handle-color: white,
  ));

  @include mat.form-field-overrides((
    container-height: 35px,
    container-vertical-padding: 5px,
    container-text-line-height: 30px,
    outlined-outline-color: #c2c2c2,
  ));

  @include mat.expansion-overrides((
    container-shape: 0,
    header-collapsed-state-height: 52px,
    container-background-color: white,
    header-hover-state-layer-color: white,
    header-focus-state-layer-color: white,
  ));
}

.mat-accent {
  @include mat.theme((
    color: theme-colors.$secondary-palette,
  ))
}

.mat-warn {
  @include mat.theme((
    color: theme-colors.$error-palette,
  ))
}

.mat-neutral {
  @include mat.theme((
    color: theme-colors.$neutral-palette,
  ))
}

.btn-rounded-0 {
  @include mat.button-overrides((
    filled-container-shape: 0,
    outlined-container-shape: 0,
  ));
}

.mat-mdc-icon-button {
  line-height: 1;
}

.mat-mdc-menu-item {
  min-height: 35px !important;

  &:not([disabled]):hover {
    color: white;
  }
}

.graphic-toggle-group {
  @include mat.button-toggle-overrides((
    height: 25px,
    selected-state-background-color: black,
    selected-state-text-color: white,
  ))
}
