
@media (max-width: 1399px) {
}

@media (max-width: 991px) {
}

@media (max-width: 767px) {
.DetailTile {
	min-width: inherit;
}
}

@media (max-width: 575px) {
.DetailTile {
	width: 100%;
	margin-right: 0px;
}
.DetailComboBox.Pricing .TermColumn {
	width: 50px;
}
}

@media (max-width: 374px) {
}
