@media (max-width: 1399px) {
}

@media (max-width: 991px) {
  /*----------------------------------------------------- Grids --------------------------------------------*/
  .GridHeaderContainer {
    padding: 15px 30px 15px;
    background-color: #f5f5f5;
  }
  .GridButtonContainer {
    padding: 0px 30px 30px;
  }
  .GridSectionContainer {
    padding: 0px;
  }
  .GridSectionContainer h1,
  .GridSectionContainer h3 {
    padding: 0px 20px 0px 20px;
  }
  .GridSectionContainer p {
    display: none;
  }
  .ColumnHeader {
    display: none !important;
  }
  .ViewportContainer {
    width: 100%;
    overflow: hidden;
    display: block;
  }
  .MobileTopBorder {
    border-top: 1px solid #c2c2c2;
  }
  .GridContainer {
    width: 100%;
    position: relative;
    left: 0;
    display: flex;
    flex-wrap: wrap;
    border-bottom: none;
  }
  .RowContainer {
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #c2c2c2;
    width: 100%;
    position: relative;
  }
  .RowContainer:hover .CellContainer {
    background-color: transparent;
  }
  .RowContainer.Flag .CellContainer:first-child {
    background: none;
  }
  .RowContainer.Flag {
    position: relative;
  }
  .RowContainer.Flag::before {
    position: absolute;
    content: "";
    width: 7px;
    left: 0px;
    top: 0px;
    bottom: 0px;
  }
  .RowContainer.GoodFlag::before {
    background-color: #9cad3c;
  }
  .RowContainer.WarningFlag::before {
    background-color: #da4b4b;
  }
  .RowContainer.CautionFlag::before {
    background-color: #ffc107;
  }
  .RowContainer.MiscFlag::before {
    background-color: #624eab;
  }
  .RowContainer:last-child .CellContainer {
    border-bottom: none;
  }
  .CellContainer {
    display: block;
    border-top: none;
    padding: 5px 30px !important;
    font-size: 14px !important;
  }
  .CellContainer.RightAlign {
    text-align: left;
  }
  .CellContainer.CenterAlign {
    text-align: left;
  }
  .CellContainer img {
    margin-bottom: -8px;
  }
  .GridContainer .CellContainer[data-title]:before,
  .GridContainer .CellContainer[data-title]:before {
    content: attr(data-title) ":\00A0";
    font-weight: 400;
    color: #000000;
    display: inline-block;
    width: 150px;
  }
  .SortableGridSectionContainer,
  .SortableGridSectionContainer_NoFlags {
    padding: 0px;
  }
  .HandleContainer {
    display: none !important;
  }
  .ActionDropdwonCell {
    display: none !important;
  }
  .RowContainer.WarningFlag:hover .CellContainer:first-child,
  .RowContainer.CautionFlag:hover .CellContainer:first-child,
  .RowContainer.GoodFlag:hover .CellContainer:first-child,
  .RowContainer.MiscFlag:hover .CellContainer:first-child {
    background-color: inherit;
  }
  /*----------------------------------------------------- Misc --------------------------------------------*/
  .LinkColumn {
    width: auto;
    text-align: left;
  }
  .DeleteColumn {
    position: static;
  }
  .DeleteButton {
    height: 25px;
    width: 25px;
    top: 50%;
    margin-top: -22px;
    right: 15px;
  }
  .DeleteButton::before {
    font-size: 18px;
    margin-left: -4px;
    margin-top: -2px;
  }
}

@media (max-width: 767px) {
}

@media (max-width: 575px) {
  .GridHeaderContainer {
    padding: 15px 15px 15px;
  }
  .GridButtonContainer {
    padding: 0px 15px 30px;
  }
  .GridContainer .CellContainer[data-title]:before,
  .GridContainer .CellContainer[data-title]:before {
    width: auto;
    padding-right: 20px;
  }
  .CellContainer {
    display: block;
    border-top: none;
    padding: 5px 15px !important;
  }
  /*----------------------------------------------------- Pagination --------------------------------------------*/
  .PaginationDisplay {
    display: none;
  }
  .PaginationLabel,
  .PageNumber input[type="text"],
  .PaginationTotal,
  .PaginationButton,
  .ResultsNumber input,
  .ResultsNumberLabel {
    height: 40px;
    line-height: 40px;
  }
  .PaginationButton {
    width: 40px;
  }
  .PageNumber input[type="text"] {
    font-size: 16px;
  }
}

@media (max-width: 374px) {
}
