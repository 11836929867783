@use '@angular/material' as mat;
@use 'styles_theme-colors' as theme-colors;

html {
  @include mat.theme((
    color: (
      primary: theme-colors.$primary-palette,
      tertiary: theme-colors.$tertiary-palette,
      theme-type: light,
    ),
    typography: (
      plain-family: Open Sans,
      brand-family: Open Sans,
      bold-weight: 900,
      medium-weight: 500,
      regular-weight: 300,
    ),
    density: -2
  ));

  @include mat.form-field-overrides((
    filled-container-shape: 0,
    outlined-container-shape: 1px,
  ));

  @include mat.button-overrides((
    filled-container-shape: 4px,
    outlined-container-shape: 4px,
    filled-container-height: 40px,
    outlined-container-height: 40px,
  ));

  @include mat.datepicker-overrides((
    calendar-container-background-color: white,
    calendar-container-shape: 16px,
    calendar-container-elevation-shadow: 4.0px 8.0px 8.0px hsl(0deg 0% 0% / 0.38),
  ));

  @include mat.select-overrides((
    panel-background-color: white,
    container-elevation-shadow: 4.0px 8.0px 8.0px hsl(0deg 0% 0% / 0.38),
  ));

  @include mat.button-toggle-overrides((
    shape: 0,
  ));
}

.mat-accent {
  @include mat.theme((
    color: theme-colors.$secondary-palette,
  ))
}

.mat-warn {
  @include mat.theme((
    color: theme-colors.$error-palette,
  ))
}

.mat-neutral {
  @include mat.theme((
    color: theme-colors.$neutral-palette,
  ))
}

.btn-rounded-0 {
  @include mat.button-overrides((
    filled-container-shape: 0,
    outlined-container-shape: 0,
  ));
}

.mat-mdc-icon-button {
  line-height: 1;
}
