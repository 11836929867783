/*----------------------------------------------------- Grids --------------------------------------------*/

.GridHeaderContainer {
  padding: 30px 50px 0px;
}

.GridButtonContainer {
  padding: 0px 50px 30px;
}

.GridSectionContainer {
  padding: 0px 50px;
}

.ViewportContainer {
  display: table;
  width: 100%;
  position: relative;
}

.GridContainer {
  display: table;
  width: 100%;
}

.RowContainer {
  display: table-row;
}

.RowContainer:hover {
  cursor: pointer;
}

.RowContainer:hover .CellContainer {
  background-color: #f5f5f5;
}

.RowContainer:last-child .CellContainer {
  border-bottom: 1px solid #c5c5c5;
}

.RowContainer.NoResultsRow {
  cursor: default !important;

  .CellContainer {
    // width: 100%;
    padding: 15px 0px;
    text-align: center;
  }
}

.CellContainer {
  display: table-cell;
  border-top: 1px solid #c5c5c5;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 20px;
  position: relative;
  vertical-align: top;
  clear: both;
  font-size: 14px !important;
}

.CellContainer:last-child {
  padding-right: 0px;
}

.CellContainer.RightAlign {
  text-align: right;
}

.CellContainer.CenterAlign {
  text-align: center;
}

.CellContainer.MiddleAlign {
  vertical-align: middle;
}

.CellContainer:first-child {
  padding-left: 15px;
}

.ColumnHeader {
  font-size: 12px;
}

.Sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background-color: #ffffff;
  z-index: 1;
}

.StickyFormatting {
  background-color: #0096db;
  color: #ffffff;
}

.StickyRowLabel {
  font-size: 12px;
}

.StickyRowLabelFormatting {
  z-index: 1;
  position: relative;
}

.StickyRowLeftFormatting {
  position: absolute;
  left: -50px;
  width: calc(100% + 65px);
  top: 0;
  bottom: 0;
  background-color: #0096db;
  z-index: 0;
}

.StickyRowRightFormatting {
  position: absolute;
  right: -50px;
  width: calc(100% + 50px);
  top: 0;
  bottom: 0;
  background-color: #0096db;
  z-index: 0;
}

.ColumnHeader .CellContainer {
  border-top: none !important;
  vertical-align: bottom;
  font-size: 12px !important;
}

.RowContainer.ColumnHeader:hover .CellContainer {
  cursor: default;
  background-color: #ffffff !important;
}

/*------------------------------------------------------ Action Dropdow ----------------------------------------------------- */

.ActionDropdownContainer {
  position: absolute;
  right: 0px;
}

.ActionDropdownIcon {
  height: 34px;
  line-height: 34px;
  padding: 0px 10px 0px 10px;
  background-image: url(/assets/images/icons/icon_lightning_grey.svg);
  background-size: auto 15px;
  background-repeat: no-repeat;
  background-position: center right 17px;
  width: 35px;
  clear: both;
  position: absolute;
  top: 12px;
  right: 0px;
  margin-top: -17px;
  border: 1px solid transparent;
}

.ActionDropdownIcon::after {
  border-style: solid;
  border-color: #6b6b6b;
  border-width: 1px 1px 0 0;
  content: '';
  display: inline-block;
  height: 4px;
  width: 4px;
  position: relative;
  top: -4px;
  right: -4px;
  transform: rotate(135deg);
}

.ActionDropdownContainer:hover .ActionDropdownIcon {
  background-color: #ffffff;
  border: 1px solid #e2e2e2;
}

.ActionDropdownMenu {
  display: none;
  position: absolute;
  top: 0px;
  right: -1px;
  width: 250px;
  text-align: left;
  padding-bottom: 10px;
  z-index: 300;
  padding-top: 32px;
}

.ActionDropdownIcon:hover .ActionDropdownMenu {
  display: inline;
}

.ActionDropdownMenu::before {
  content: '';
  position: absolute;
  right: 0px;
  top: 32px;
  width: 35px;
  height: 1px;
  background-color: #ffffff;
}

.ActionMenu {
  border: 1px solid #e2e2e2;
  padding: 0;
  margin: 0;
  background-color: #ffffff;
}

.ActionMenu li {
  list-style: none;
  line-height: 24px;
  padding-left: 20px;
  padding-right: 20px;
}

.ActionMenu li:hover {
  background-color: #0096db;
  color: #ffffff;
  cursor: pointer;
}

.ActionMenu li:first-child {
  margin-top: 10px;
}

.ActionMenu li:last-child {
  margin-bottom: 10px;
}

/*----------------------------------------------------- Flags --------------------------------------------*/
.RowContainer.Flag .CellContainer:first-child {
}

.RowContainer.WarningFlag .CellContainer:first-child {
  background: url(/assets/images/global/tile_warning-flag.png);
  background-position: left;
  background-repeat: repeat-y;
  background-color: #ffffff;
}

.RowContainer.WarningFlag .FlagLabel {
  color: #da4b4b;
}

.RowContainer.CautionFlag .CellContainer:first-child {
  background: url(/assets/images/global/tile_caution-flag.png);
  background-position: left;
  background-repeat: repeat-y;
  background-color: #ffffff;
}

.RowContainer.CautionFlag .FlagLabel {
  color: #ffc107;
}

.RowContainer.GoodFlag .CellContainer:first-child {
  background: url(/assets/images/global/tile_good-flag.png);
  background-position: left;
  background-repeat: repeat-y;
  background-color: #ffffff;
}

.RowContainer.GoodFlag .FlagLabel {
  color: #9cad3c;
}

.RowContainer.MiscFlag .FlagLabel {
  color: #624eab;
}

.RowContainer.MiscFlag .CellContainer:first-child {
  background: url(/assets/images/global/tile_misc-flag.png);
  background-position: left;
  background-repeat: repeat-y;
  background-color: #ffffff;
}

.RowContainer.NewFlag .CellContainer:first-child {
  background: url(/assets/images/global/tile_new-flag.png);
  background-position: left;
  background-repeat: repeat-y;
  background-color: #ffffff;
}

.RowContainer.WarningFlag:hover .CellContainer:first-child,
.RowContainer.CautionFlag:hover .CellContainer:first-child,
.RowContainer.GoodFlag:hover .CellContainer:first-child,
.RowContainer.MiscFlag:hover .CellContainer:first-child,
.RowContainer.NewFlag:hover .CellContainer:first-child {
  background-color: #f5f5f5;
}

/*----------------------------------------------------- Pagination --------------------------------------------*/
.PaginatioinNavigation {
  float: left;
}

.PaginationLabel {
  height: 30px;
  line-height: 30px;
  float: left;
  margin-right: 10px;
}

.PageNumber {
  float: left;
  margin-right: 10px;
}

.PageNumber input[type='text'] {
  width: 40px;
  padding: 0px 10px;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
}

.PaginationTotal {
  height: 30px;
  line-height: 30px;
  float: left;
  margin-right: 10px;
}

.PaginationButton {
  font-size: 14px;
  float: left;
  background-color: #22252e;
  color: #ffffff;
  height: 30px;
  line-height: 30px;
  width: 30px;
  position: relative;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
}

.PaginationButton:hover {
  background-color: #040405;
  cursor: pointer;
}

.PaginationButton.PreviousPage::before {
  border-style: solid;
  border-width: 2px 2px 0 0;
  content: '';
  display: inline-block;
  height: 7px;
  position: absolute;
  width: 7px;
  top: 50%;
  margin-top: -5px;
  left: 50%;
  margin-left: -3px;
  transform: rotate(-135deg);
}

.PaginationButton.NextPage::before {
  border-style: solid;
  border-width: 2px 2px 0 0;
  content: '';
  display: inline-block;
  height: 7px;
  position: absolute;
  width: 7px;
  top: 50%;
  margin-top: -5px;
  left: 50%;
  margin-left: -6px;
  transform: rotate(45deg);
}

.PaginationDisplay {
  float: right;
}

.ResultsNumber {
  float: left;
  margin-right: 10px;
}

.ResultsNumber input {
  padding: 0px 10px;
  height: 30px;
  line-height: 30px;
  border-width: 1px;
  width: 80px;
}

.ResultsNumberLabel {
  float: left;
  height: 30px;
  line-height: 30px;
}

/*----------------------------------------------------- Misc --------------------------------------------*/
.LinkColumn {
  width: 150px;
  text-align: right;
}

.DeleteColumn {
  width: 50px;
  text-align: right;
  position: relative;
}

.DeleteButton {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #272b35;
  position: absolute;
  right: 5px;
  top: 17px;
  display: none;
}

.DeleteButton::before {
  content: 'x';
  position: absolute;
  font-size: 14px;
  text-align: center;
  top: 0px;
  margin-top: -1px;
  left: 50%;
  margin-left: -3px;
  color: #ffffff;
}

.RowContainer.Deletable .DeleteButton {
  display: inline;
}

.Deletable .DeleteButton {
  display: inline;
}

.GridPopup {
  display: none;
  position: absolute;
  width: 300px;
  padding: 10px;
  top: 40px;
  background-color: #ffffff;
  border: 1px solid #e2e2e2;
  z-index: 100;
}

/*----------------------------------------------------- Sortable Grids --------------------------------------------*/
.SortableGridSectionContainer {
  padding: 0px 50px 0px 50px;
}

.SortableGridSectionContainer_NoFlags {
  padding: 0px 50px 0px 50px;
}

.SortableGridSectionContainer .CellContainer:first-child {
  padding-left: 15px;
}

.SortableGridSectionContainer_NoFlags .CellContainer:first-child {
  padding-left: 0px;
}

.SortableRowPlaceholder {
  border: 1px dashed #1f92c2;
  height: 45px;
  display: block;
  width: 100%;
  margin-bottom: 0px;
}

.HandleContainer {
  padding-right: 20px;
  width: 50px;
  vertical-align: middle;
  text-align: center;
}

.SortableGridSectionContainer .Handle,
.SortableGridSectionContainer_NoFlags .Handle {
  background-image: url(/assets/images/global/icon_handle.png);
  background-position: center;
  background-repeat: no-repeat;
  height: 30px;
}

.Handle:hover {
  background-image: url(/assets/images/global/icon_handle_hover.png);
}
