/*----------------------------------------------------- Details --------------------------------------------*/
.DetailContainer {
	display: flex;
	flex-wrap: wrap;
}
.DetailTile {
	margin-bottom: 20px;
	padding-bottom: 5px;
	margin-right: 50px;
	min-width: 290px;
}
.DetailSectionContainer {
	float: left;
	clear: both;
	margin-bottom: 15px;
}
.DetailComboBox {
	position: relative;
	float: left;
	margin-right: 10px;
}
.DetailLabel {
	float: left;
	width: 120px;
}
.DetailLabel::after {
}
.DetailLabel.Blank::after {
	color: #ffffff;
}
.DetailData {
	float: left;
}
/*----------------------------------------------------- Flags --------------------------------------------*/
.DetailData.Flag {
	padding-left: 10px;
}
.DetailData.WarningFlag {
	border-left: 5px solid #da4b4b
}
.DetailData.CautionFlag {
	border-left: 5px solid #ffc107
}
.DetailData.GoodFlag {
	border-left: 5px solid #9cad3c
}
/*----------------------------------------------------- Pricing Tiles --------------------------------------------*/
.PeriodicityLabel {
	float: left;
	clear: both;
}
.PeriodicityLabel span {
	color: #e5a200;
	font-weight: 400;
}
.DetailComboBox.Pricing {
	margin-bottom: 20px;
	margin-right: 20px;
}
.DetailComboBox.Pricing .PricingLabel {
	margin-bottom: 10px;
}
.DetailComboBox.Pricing .TermColumn {
	width: 70px;
}
.DetailComboBox.Pricing .FactorColumn {
	width: 100px;
}
.DetailComboBox.Pricing .PaymentColumn {
	width: 100px;
}
.DetailComboBox.QuotePreview {
	margin-right: 100px;
	margin-bottom: 10px;
}
.DetailComboBox.QuotePreview .PricingLabel {
	margin-bottom: 10px;
}
.DetailComboBox.QuotePreview .TermColumn {
	width: 70px;
	margin-bottom: 5px;
}
.DetailComboBox.QuotePreview .FactorColumn {
	width: 100px;
	margin-bottom: 5px;
}
.DetailComboBox.QuotePreview .PaymentColumn {
	width: 100px;
	margin-bottom: 5px;
}
/*----------------------------------------------------- Misc --------------------------------------------*/
.ParagraphHeader.Editable {
	float: left
}
.DetailEditLink {
	line-height: 26px;
	float: left;
	margin-left: 20px;
}
.SortableGridSectionContainer .FormSectionContainer, .SortableGridSectionContainer_NoFlags .FormSectionContainer, .GridSectionContainer .FormSectionContainer {
	padding: 0;
	float: none;
}
.CellContainer.Description:hover .GridPopup.Description {
	display: block;
}
.CellContainer .InlineHighlight {
	display: inline-block;
	padding-left: 5px;
	padding-right: 5px;
}